<template>
	<div class="lunch-constructor">
		<div class="lunch-constructor__flex">
			<div class="lunch-constructor__restaurant-info">
				<RestaurantInfo
					:name="curRest.name || ''"
					:ogrn="curRest.ogrn || ''"
					:inn="curRest.inn || ''"
					@change="showRestaurantsForm"
				/>
			</div>
			<div class="lunch-constructor__swipe-wrapper">
				<v-touch
					ref="tapper"
					v-on:swipeup="swipeOpen"
					v-on:swipedown="swipeClose"
				>
					<div
						class="lunch-constructor__swipe-content"
						:class="{'lunch-constructor__swipe-content--priority': showMobileRest,}"
					>
						<div
							class="lunch-constructor__swipe-content-wr"
							:class="{ 'lunch-constructor__swipe-content-wr--fixed': showMobileRest }"
							ref="swipeRest"
						>
							<div
								class="lunch-constructor__swipe-top"
								@click="swipeClose"
							>
								<span class="lunch-constructor__swipe-feature"></span>
							</div>
							<div>
								<Restaurants
									:restaurants="$store.state.restaraunts"
									@selectRestaurant="changeRest"
									:showMobileRest="showMobileRest"
								/>
							</div>
						</div>
					</div>
				</v-touch>
			</div>
			<div
				class="lunch-constructor__info"
				v-if="!endLunch"
			>
				<label class="lunch-constructor__title">
					<input
						type="text"
						v-model="name"
						:placeholder="getPlaceholder"
						:style="{ width: 19 * name.length + 'px' }"
					/>
					<div class="lunch-constructor__title-right">
						<span>{{ getTitleRight }}</span>
						<svg
							width="20"
							height="20"
							class="lunch-constructor__rename-icon"
						>
							<use xlink:href="#pencil"></use>
						</svg>
					</div>
				</label>
			</div>
			<LunchMenu
				class="lunch-constructor__lunch-menu"
				@select="selectCategory"
				:lunch="returnActiveLunch"
				:activeCategory="active"
				:constructorPage="true"
				:sticky="lunchMenuSticky"
				:style="{top: `${lunchMenuShift}px`}"
			/>
			<div
				class="lunch-constructor__filler"
				style="height: 134px"
				v-if="lunchMenuSticky"
			></div>
			<div
				class="lunch-constructor__list"
				:class="{ 'lunch-constructor__list--active': activeCatagory}"
				v-for="(lunchGroupd, i) in newLunchArray[currentLunchIndex]"
				:key="i"
				:style="{
					backgroundColor: '#1ef3350',
					marginBottom: '25px',
					borderRadius: '40px',
				}"
			>
				<SectionTitle
					class="lunch-constructor__list-title"
					:ref="lunchGroupd.type"
				>
					<span
					>Выберите
						<span class="lunch-constructor__list-title--green">
							{{ lunchGroupd.title }}
						</span>
						из списка
					</span>
				</SectionTitle>
				<template v-if="!lunchGroupd.checked">
					<LunchItem
						class="list-remove-item"
						v-for="lunchItem in lunchItems[lunchGroupd.type]"
						:key="JSON.stringify(lunchItem)"
						:lunchItem="lunchItem"
						:imgs="[lunchItem?.preview_picture?.thumb, lunchItem?.detail_picture?.thumb]"
						:categoryBackground="getCategoryColor(lunchGroupd.type)"
						:activeFood="returnActiveProduct(lunchGroupd.type)"
						@chosefood="choseFood($event, lunchGroupd.type)"
					/>
				</template>
				<div
					class="lunch-constructor__item-choose"
					v-else
				>
					<div class="lunch-constructor__item-choose__status">
						<svg
							width="40"
							height="40"
						>
							<use xlink:href="#lunch-ok"></use>
						</svg>
						{{ getItemChoose }}
					</div>
					<button @click="changeLunchCheckedStatus(lunchGroupd.type, false)">
						Изменить
					</button>
				</div>
			</div>
			<!--				пока эти блоки не внедрены -->
			<!--			<div class="lunch-constructor__item">-->
			<!--				<h3>Не забудьте добавить</h3>-->
			<!--				<AddProductCart/>-->
			<!--			</div>-->
			<!--			<div class="lunch-constructor__item">-->
			<!--				<h3>Не забудьте добавить</h3>-->
			<!--				<DontForgetRadio/>-->
			<!--			</div>-->
		</div>
		<div class="lunch-constructor__wrapper">
			<div
				class="lunch-constructor__modal-mask"
				v-if="show"
				@click="swipeDown"
			></div>
			<v-touch
				ref="tapper"
				v-on:swipeup="swipeUp"
				v-on:swipedown="swipeDown"
			>
				<div
					class="lunch-constructor__cart-swiper-wr"
					:class="{
						'lunch-constructor__cart-swiper-wr_priority': this.show,
					}"
				>
					<div
						class="lunch-constructor__cart"
						:class="{ 'lunch-constructor__cart_fixed': show }"
						@click="swipeUp"
						ref="lunchConstructor"
					>
						<LunchCart
							@tocart="$emit('tocart')"
							@setname="setNames"
							@setcurrentlunchname="setCurrentLunchName"
							@setactivelunch="setCurrentLunchIndex"
							@removemeal="removeMeal"
							@replay-lanch="replayLanch"
							@close="swipeDown"
							class="list-remove-item"
							:lunchesOuter="lunchArray"
							:currentLunchIndex="currentLunchIndex"
							:currentlunchname="name"
							:show="show"
						/>
					</div>
				</div>
			</v-touch>
		</div>
		<Popup :visible.sync="showRestSelection">
			<Restaurants
				:restaurants="$store.state.restaraunts"
				@selectRestaurant="changeRest"
			/>
		</Popup>
	</div>
</template>

<script>

import LunchCart from "@/components/block/LunchCart.vue";
import LunchItem from "@/components/block/LunchItem.vue";
import LunchMenu from "@/components/block/LunchMenu.vue";
import AddProductCart from "@/components/block/AddProductCart.vue";
import RestaurantInfo from "@/components/block/Menu/RestaurantInfo.vue";
import Restaurants from "@/components/popup/Restaurants.vue";
import DontForgetRadio from "@/components/block/DontForgetRadio.vue";
import SectionTitle from "@/components/block/SectionTitle.vue";
import {mapGetters} from "vuex";

export default {
	props: {
		endLunch: {
			type: Boolean,
			default: false,
		},
	},
	components:
		{
			LunchCart,
			LunchItem,
			AddProductCart,
			DontForgetRadio,
			SectionTitle,
			LunchMenu,
			RestaurantInfo,
			Restaurants,
		},

	/**
	 * дата
	 */
	data()
	{
		return {
			swipeRestHeight: 0,
			showMobileRest: false,
			showRestSelection: false,
			lunchMenuSticky: false,
			lunchMenuShift: 0,
			show: false,
			activeLuch: false,

			name: "",

			// активная категория, точноо код активной категории то есть типа блюда
			// сходу не понятно, но так и есть
			active: false,

			luchDone: false,
			meals: false,
			currentLunchIndex: 0,
			lunchArray: [],
			names: false,
			lunchItems: {},
			lunchConstructorHeight: 0,
			second: false,
			newLunchArray: [],
			activeCatagory: false
		};
	},

	/**
	 ** если в сторе есть выбранный ланч - работaем нет - не работаем
	 ** создаем массив ланчей из объектов с категориями ланчей
	 **/
	mounted()
	{
		this.swipeRestHeight = this.$refs.swipeRest.offsetHeight;
		this.$refs.swipeRest.style.bottom = "-" + this.$refs.swipeRest.offsetHeight + "px";
		this.activeLunch = this.$store.state.businessMeal;
		if (!this.activeLunch)
			return false;

		const lunchTypes = this.activeLunch.types.map(el => el.code);

		for (let lunchIndex = 0; lunchIndex < this.activeLunch.persons_count; lunchIndex++)
		{
			this.setNewLunchArray(lunchIndex, lunchTypes);
			let lunch = {};
			for (const index in this.activeLunch.types)
				lunch[this.activeLunch.types[index].code] = false;

			this.lunchArray.push(lunch);
		}

		const keys = Object.keys(this.lunchArray[0]);
		this.active = keys[0];
		this.constructLunchMeals(keys);

		this.lunchConstructorHeight = this.$refs.lunchConstructor.offsetHeight;
		this.$refs.lunchConstructor.style.bottom = "-" + this.$refs.lunchConstructor.offsetHeight + "px";
		window.addEventListener("resize", this.updConstrHeight, {passive: true});
		window.addEventListener("scroll", this.updateLunchMenuVisibility, {passive: true});
	},

	computed:
		{
			...mapGetters({
				curRest: "order/currentRestaurant",
			}),
			/**
			 ** возвращает placeholder для ввода имени
			 **/
			getPlaceholder()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

				return `Для кого ${mealText}?`
			},
			/**
			 ** возвращает заголовок с напоминанием ввода имени
			 **/
			getTitleRight()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

				return `Не забудьте подписать для кого ${mealText}`;
			},
			/**
			 ** возвращает текст с уведомления
			 **/
			getItemChoose()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

				return `Добавлено в ${mealText}`;
			},
			/**
			 ** возвращает активный ланч
			 **/
			returnActiveLunch()
			{
				return this.lunchArray.length > 0 ? this.lunchArray[this.currentLunchIndex] : false;
			},
			/**
			 ** проверка на завершенность ланчей
			 **/
			returnCartStatus()
			{
				for (const index in this.lunchArray)
				{
					const lunch = this.lunchArray[index];
					for (const mealIndex in lunch)
						if (!lunch[mealIndex])
							return false;
				}
				return true;
			},
		},
	watch:
		{
			// следить за изменением активного ланча и если нет имени то дать имя по умолчанию иначе менять имя которое вводит пользователь.
			currentLunchIndex()
			{
				if (this.names)
					this.name = this.names[this.currentLunchIndex];

				if (this.currentLunchIndex)
				{
					if (this.name === '')
					{
						this.name = this.$store.state.user.NAME || "";
					}
				}
			},
			'returnActiveLunch.name'()
			{
				if (this.returnActiveLunch.name)
				{
					window.scrollTo({left: 0, top: 100, behavior: "smooth"});
				}
			},
		},
	methods:
		{
			showRestaurantsForm(width)
			{
				if (width <= 767)
				{
					this.swipeOpen();
				} else
				{
					this.showRestSelection = true;
				}
			},
			swipeOpen()
			{
				if (window.innerWidth <= 767)
				{
					this.showMobileRest = true;
					this.$refs.swipeRest.style.bottom = "0px";
					document.querySelector("body").classList.add("page-no-scroll");
				}
			},
			swipeClose()
			{
				if (window.innerWidth <= 767)
				{
					this.showMobileRest = false;
					this.$refs.swipeRest.style.bottom = "-" + this.swipeRestHeight + "px";
					document.querySelector("body").classList.remove("page-no-scroll");
				}
			},
			/**
			 * открывает модальное окно для выбора ресторана
			 */
			async changeRest(rest)
			{
				this.loaded = false;
				this.showRestSelection = false;
				this.$store.commit("setRestarauntId", rest.id);
				await this.$store.dispatch("getMenu");
				await this.$store.dispatch("getCart");
				this.swipeClose();
				this.loaded = true;
			},
			/**
			 * задает имя текущего ланча
			 */
			setNewLunchArray(index, keys)
			{
				this.newLunchArray[index] = this.$store.state.mealByGroups
					.filter(el => keys.includes(el.type))
					.map(el => ({
						...el,
						checked: false,
					}));
			},

			//задает фоновый цвет для lunchItem
			getCategoryColor(category)
			{

				let res = this.$store.state.menuCategories.find(c => c.type === category);

				if (res.color)
					return `#${res.color}`;
				else
					return "#fff";
			},
			// Проверка видимости меню выбора категории
			updateLunchMenuVisibility()
			{
				if (this.$store.state.ui.headerVisibility && window.innerWidth >= 990)
				{
					this.lunchMenuShift = 120;
				} else
				{
					this.lunchMenuShift = 0;
				}

				if (window.scrollY >= 380 && window.innerWidth >= 990)
				{
					this.lunchMenuSticky = true;
				} else this.lunchMenuSticky = window.scrollY >= 350 && window.innerWidth < 990;
			},
			setCurrentLunchName(newValue)
			{
				this.$emit("setcurrentlunchname", newValue);
			},

			// Скролл до выбранной категории
			selectCategory(type)
			{
				this.active = type;
				const el = this.$refs[type].$el || this.$refs[type][0].$el;
				let top = el.getBoundingClientRect().top + window.pageYOffset - 100;

				if (window.scrollY > el.getBoundingClientRect().top + window.pageYOffset && window.innerWidth > 990)
				{
					top -= 120;
				}

				window.scrollTo({
					top,
					behavior: "smooth",
				});
			},
			/**
			 * меняет имена если их поменяли в корзине ланчей
			 */
			setNames(names)
			{
				this.names = names;
			},
			/**
			 * проверка на завершенность ланчей
			 */
			buyCheck()
			{
				for (const index in this.lunchArray)
				{
					const lunch = this.lunchArray[index];
					for (const mealIndex in lunch)
					{
						if (!lunch[mealIndex])
						{

							this.currentLunchIndex = index;
							this.active = mealIndex;
							return false;
						}
					}
				}
				return true;
			},

			/**
			 * удаляет блюдо из ланча
			 */
			removeMeal(lunchIndex, type)
			{
				if (type === "second")
				{
					this.removeSecond(lunchIndex);
					return;
				}
				this.changeLunchCheckedStatus(type, false);
				this.lunchArray[lunchIndex][type] = false;
			},
			/**
			 * удаляет из ланча второе
			 */
			removeSecond(lunchIndex)
			{
				this.second = false;

				if (this.lunchArray[lunchIndex]["garnir"])
				{
					this.lunchArray[lunchIndex]["garnir"] = false;
					this.newLunchArray[this.currentLunchIndex].find(el => el.type === "garnir").checked = false;
				}

				if (typeof this.lunchArray[lunchIndex]["hot"] === "object" && this.lunchArray[lunchIndex]["hot"].type.code === "second")
				{
					this.lunchArray[lunchIndex]["hot"] = false;
					this.newLunchArray[this.currentLunchIndex].find(el => el.type === "hot").checked = false;
				}
			},
			/**
			 * устанавливает активный ланч и скроллит влево на мобиле категории
			 */
			setCurrentLunchIndex(index)
			{
				this.currentLunchIndex = index;
				for (const key in this.lunchArray[this.currentLunchIndex])
				{
					if (this.lunchArray[this.currentLunchIndex][key])
						continue;

					this.active = key;
					break;
				}
			},

			/**
			 * приводит блюда в божеский вид для конструктора
			 */
			constructLunchMeals(categories)
			{
				let haveSecond = false;
				if (categories.indexOf("garnir") !== -1 && categories.indexOf("hot") !== -1)
					haveSecond = true;

				this.meals = this.$store.state.meals;
				for (let index in categories)
					this.$set(this.lunchItems, categories[index], []);

				for (let index in this.meals)
				{
					const item = this.meals[index];
					if (item.tk === 0)
						continue;
					if (categories.indexOf(item.type.code) !== -1)
						this.lunchItems[item.type.code].push(item);
				}

				if (haveSecond) this.addSecondInHot(categories);
			},

			/**
			 * добавляет вторые блюда в категорию горячее
			 */
			addSecondInHot(categories)
			{
				if (!~categories.indexOf("garnir"))
					return;

				for (let meal of this.meals)
					if (meal.type.code === "second" && meal.tk)
						this.lunchItems["hot"].push(meal);
			},

			/**
			 * swipeUp
			 * выключает скролл на странице
			 */
			swipeUp()
			{
				if (window.innerWidth < 1200)
				{
					this.show = true;
					this.$refs.lunchConstructor.style.bottom = "0px";
					document.querySelector("body").classList.add("page-no-scroll");
				}
			},

			/**
			 * swipeDown
			 * включает странице скролл
			 */
			swipeDown()
			{
				if (window.innerWidth < 1200)
				{
					this.show = false;
					this.$refs.lunchConstructor.style.bottom = "-" + this.lunchConstructorHeight + "px";
					document.querySelector("body").classList.remove("page-no-scroll");
				}
			},

			/**
			 * смена категории по клику на категорию
			 */
			// changeActiveCategory(category)
			// {
			// 	this.active = category;
			// },

			/**
			 * смена активного состояния галочек в конструкторе
			 * @param {string} type - категория блюда
			 * @param {boolean} value - значение галочки
			 */
			changeLunchCheckedStatus(type, value)
			{
				this.active = type;
				const idx = this.newLunchArray[this.currentLunchIndex].findIndex(el => el.type === type);

				if (idx > -1)
				{
					this.newLunchArray[this.currentLunchIndex][idx].checked = value;
				}
			},

			/**
			 * выбирает продукт в категории ланча и переключает с задержкой категорию
			 */
			choseFood(product, type)
			{
				this.removeMeal(this.currentLunchIndex, type);
				this.changeLunchCheckedStatus(type, true);

				//скролить до активной категории
				setTimeout(() =>
				{
					this.selectCategory(type);
				}, 200);

				setTimeout(() =>
				{
					if (this.returnCartStatus)
					{
						this.$emit("lunchdone", this.lunchArray, this.names);
					}
				}, 600);

				// если было выбрано второе, и выбрали гарнир, либо горячее
				if (this.second && (product.type.code === "garnir" || product.type.code === "hot"))
					this.removeSecond(this.currentLunchIndex);


				this.lunchArray[this.currentLunchIndex][this.active] = product;

				// если добавили второе
				if (product.type.code === "second")
					this.choseSecond(product);


				const keys = Object.keys(this.lunchArray[this.currentLunchIndex]),
					lastKey = keys[keys.length - 1],
					self = this;


				for (const index in this.lunchArray[this.currentLunchIndex])
				{
					if (index === lastKey && this.lunchArray[this.currentLunchIndex][index] !== false)
					{
						this.active = lastKey;
						this.luchDone = true;

						if (this.currentLunchIndex < this.lunchArray.length)
						{
							this.active = false;
							setTimeout(() =>
							{
								if (this.currentLunchIndex !== this.lunchArray.length - 1)
								{
									this.currentLunchIndex++;
									this.active = keys[0];
								}
							}, 700);
						}
						return;
					}
					if (this.lunchArray[this.currentLunchIndex][index] === false)
					{
						setTimeout(function ()
						{
							self.active = index;
						}, 700);
						break;
					}
				}
			},
			/**
			 * добавляет в ланч второе, ячейке для гарнира задает true
			 */
			choseSecond(productId)
			{
				this.second = true;
				this.lunchArray[this.currentLunchIndex]["hot"] = productId;
				this.lunchArray[this.currentLunchIndex]["garnir"] = true;
				this.newLunchArray[this.currentLunchIndex].find(el => el.type === "garnir").checked = true;
			},
			/**
			 * заново вычисляет расположение конструктора
			 */
			updConstrHeight()
			{
				let constructor = this.$refs.lunchConstructor;
				// let constructor =  document.querySelector('.lunch-constructor__cart');
				if (window.innerWidth < 1200)
				{
					setTimeout(() =>
					{
						this.lunchConstructorHeight = constructor.offsetHeight;

						if (!this.show)
							constructor.style.bottom = "-" + this.lunchConstructorHeight + "px";

						else
							constructor.style.bottom = "0px";

					}, 200);
				}
			},

			replayLanch(lanchIndex)
			{
				this.$set(this.lunchArray, lanchIndex, JSON.parse(JSON.stringify(this.lunchArray[lanchIndex - 1])));

				let isLastLunch = (lanchIndex >= this.lunchArray.length - 1);

				if (this.returnCartStatus)
					this.$emit("lunchdone", this.lunchArray, this.names);

				else if (!isLastLunch)
					this.setCurrentLunchIndex(lanchIndex + 1);
				// устоновить checked для продуктов
				this.changeLunchCheckedStatus(type, true);
			},
			/**
			 * возвращает выбранный продукт из ланча
			 */
			returnActiveProduct(type)
			{
				const item = this.lunchArray[this.currentLunchIndex][type];
				return item?.id || "";
			},
		},
	/**
	 * удаляет листенеры
	 */
	beforeDestroy()
	{
		window.removeEventListener("resize", this.updConstrHeight);
		window.removeEventListener("scroll", this.updateLunchMenuVisibility);
	},
};
</script>
<style lang="scss">
.lunch-constructor__swipe-wrapper
{display: none;}

.lunch-constructor__swipe-content
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	z-index: 5;

	&--priority
	{z-index: 12;}
}

.lunch-constructor__swipe-top
{
	position: absolute;
	height: 28px;
	width: calc(100% - 40px);
	margin: 0 20px 0 20px;
	background: #FFFFFF;
	text-align: center;
}

.lunch-constructor__swipe-feature
{
	display: inline-block;
	width: 44px;
	height: 4px;
	background: #E3E3E3;
	border-radius: 16px;
}

.lunch-constructor__swipe-content-wr
{
	max-width: 354px;
	margin-left: 10px;
	background: #FFFFFF;
}

input:focus + .lunch-constructor__rename-icon
{
	opacity: 0;
}

.lunch-constructor__title
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
	padding-bottom: 8px;
	height: 39px;
	border-bottom: 1px solid #E5E5E7;

	input
	{
		padding-right: 25px;
		letter-spacing: .1px;
		font-size: 30px;
		line-height: 30px;
		font-family: $mainFontBold;
		background-color: transparent;
		border: none;
		max-width: 900px;
		min-width: 305px;
		outline: none;
		color: $textColor;

		&::placeholder
		{color: #afafaf;}
	}
}

.lunch-constructor__title-right
{
	display: flex;
	align-items: center;

	span
	{
		display: block;
		font-family: $mainFontMediumNew;
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #40AE49;
	}
}

.lunch-constructor__rename-icon
{
	margin-left: 12px;
	vertical-align: sub;
	cursor: pointer;
	@include ease(.125s);
}

.list-remove-item
{transition: all .2s;}

.list-remove-enter
{
	opacity: 0;
	transform: scale(.8);
	// position: absolute;
}

.list-remove-leave-to
{
	opacity: 0;
	transform: scale(.9);
	position: absolute;
}

.list-remove-leave-active
{display: none;}

.lunch-constructor__restaurant-info
{margin-bottom: 24px;}

.lunch-constructor__item
{
	width: 102%;
	margin-bottom: 56px;

	h3
	{
		font-weight: 700;
		font-size: 24px;
		line-height: 44px;
		letter-spacing: -0.5px;
		color: #3D4248;
		border-bottom: 1px solid #E5E5E7;
		padding-bottom: 8px;
		margin-bottom: 32px;
	}

}

.lunch-constructor
{
	display: grid;
	grid-template-columns: minmax(400px, 1fr) 354px;
	gap: 0 56px;
	width: 100%;
	position: relative;

	&__lunch-menu
	{
		margin: 0 -32px 20px -32px;
		overflow-x: auto;
		padding: 0 32px 17px 32px;
		transition: top 0.2s ease-in-out;
	}
}

.lunch-constructor__flex
{flex: 1;}

.lunch-constructor__cart
{
	max-width: 354px;
	margin-left: 10px;
}

.lunch-constructor__list-title
{
	flex-basis: 100%;

	&--green
	{color: #40AE49;}
}

.lunch-constructor__wrapper
{display: flex;}

.lunch-constructor__list
{
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 0 32px;
	margin-right: -12.5px;
	flex: 1;
	align-self: flex-start;
}

.lunch-constructor-adaptive__cat-title
{display: none;}

.food-item-tooltip__value
{
	color: #fff;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 100%;
	background-color: $textColor;
	z-index: 12;
	letter-spacing: .1px;
}

.lunch-constructor__item-choose
{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 8px 16px;
	background: #F0F8F0;
	border-radius: 8px;

	&__status
	{
		display: flex;
		align-items: center;
		gap: 16px;
		font-weight: 700;
		font-size: 16px;
		color: #979797;
	}

	button
	{
		font-weight: 700;
		font-size: 16px;
		text-align: right;
		color: #40AE49;
		background-color: transparent;
		border: none;
	}
}

@media (max-width: 1439px)
{
	.lunch-constructor
	{gap: 0 48px;}
}

@media (max-width: 1200px)
{
	.lunch-constructor__cart-swiper-wr
	{
		position: fixed;
		bottom: 0;
		left: 0;
		height: 5vh;
		width: 100vw;
		z-index: 5;

		&_priority
		{z-index: 12;}
	}
	.lunch-constructor__modal-mask
	{
		position: fixed;
		z-index: 12;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #3D4248;
		display: table;
		opacity: .5;
	}
	.lunch-constructor__cart
	{
		margin-left: 0;
		position: fixed;
		z-index: 5;
		height: calc(var(--vh, 1vh) * 70);
		/*top:calc(var(--vh, 1vh) * 100 - 6px);*/
		width: 100%;
		max-width: unset;
		transition: .2s ease-in-out;

		&_fixed
		{
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
}

@media (max-width: 1024px)
{
	.lunch-constructor
	{
		grid-template-columns: minmax(400px, 1fr) 340px;
		gap: 0 32px;
	}
}

@media (max-width: 1000px)
{
	.lunch-constructor
	{display: block;}
}

@media (max-width: 767px)
{
	.lunch-constructor__swipe-wrapper
	{display: flex;}
	.lunch-constructor__swipe-content-wr
	{
		margin-left: 0;
		position: fixed;
		z-index: 5;
		height: 0;
		width: 100%;
		max-width: unset;
		transition: .2s ease-in-out;

		&--fixed
		{
			height: 100%;
			padding-right: 0;
			padding-left: 0;
			padding-top: 0;
		}
	}
	.lunch-constructor__title
	{
		margin-bottom: 9px;

		input
		{font-size: 22px;}
	}
	.lunch-constructor__title-right
	{
		span
		{display: none;}
	}
	.lunch-constructor__rename-icon
	{margin-left: -19px;}
	.lunch-constructor
	{display: block;}
	.lunch-constructor .list-remove-item
	{
		margin-right: 0;
		flex-direction: column;
		flex: 0 0 50%;
	}
	.lunch-constructor__wrapper
	{
		flex-direction: column;
		margin-left: -14.5px;
		margin-right: -14.5px;
	}
	.lunch-constructor__list
	{
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.lunch-constructor-adaptive__cat-title
	{
		font-family: $mainFontBold;
		font-size: 18px;
		line-height: 100%;
		color: $textColor;
		margin-left: 15px;
		margin-bottom: 15px;
		display: block;
	}
}

@media (max-width: 576px)
{
	.lunch-constructor__list
	{
		margin-left: 0;
		margin-right: 0;
		width: 100%;
	}
	.lunch-constructor .list-remove-item
	{flex: 0 0 100%}
}

@media (max-width: 360px)
{
	.lunch-constructor__item-choose
	{
		padding: 8px;

		&__status
		{
			font-size: 14px;
			gap: 8px;
		}

		button
		{font-size: 14px;}
	}
}
</style>
