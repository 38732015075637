<template>
	<div>
		<div class="lunch-cart__adaptive">
			<div
				class="lunch-cart__adaptive_rectangle_thrid"
				:class="{'lunch-cart__adaptive_rectangle_thrid--active': show, 'lunch-cart__adaptive_rectangle_thrid--mobile': isMobileApp}"
			></div>
			<div
				class="lunch-cart__adaptive_rectangle_second"
				:class="{'lunch-cart__adaptive_rectangle_second--active': show, 'lunch-cart__adaptive_rectangle_second--mobile': isMobileApp}"
			></div>
			<div
				class="lunch-cart__adaptive-close"
				@click.stop="close"
			>
				<img src="~@/assets/img/close.svg">
			</div>
		</div>
		<div class="lunch-cart">
			<div class="lunch-cart__top">
				<span class="lunch-cart__top-text">Ваш выбор</span>
			</div>
			<div class="lunch-cart__middle">
				<div
					class="lunch-cart__one"
					v-if="lunches.length === 1"
				>
					<div class="lunch-cart__icons">
						<img
							class="lunch-cart__icon"
							v-for="(lunchIcon, i) in lunchIconsComputed[0]"
							:key="`lunch-cart-icon-${i}-${lunchIcon}`"
							:src="require(`@/assets/img/Personal/Menu/FoodTypes/${lunchIcon}.svg`)"
						/>
					</div>
					<LunchCartItem
						:lunches="lunches[0]"
						:isgift="false"
						:lunchIndex="0"
						@removeitem="removeItem"
					/>
				</div>
				<div
					class="lunch-cart-several-wr"
					v-else
				>
					<div
						class="lunch-cart-several"
						v-for="(lunch, lunchIndex) in lunches"
						:key="lunchIndex"
					>
						<div :class="{'active-lunch': lunchesOpenned.indexOf(lunchIndex) !== -1}">
							<div
								class="lunch-cart-lunch__sev-head"
								@click="$emit('setactivelunch', lunchIndex)"
							>
								<div
									class="lunch-cart-lunch__sev-headline"
									@click="toggleShowLunchItems(lunchIndex)"
								>
									<div class="lunch-cart-lunch__sev-headline__flex">
										<div class="lunch-cart-lunch__complete-icon">
											<svg
												v-if="checkLunchesFull.indexOf(lunchIndex) !== -1"
												width="25"
												height="25"
											>
												<use xlink:href="#lunch-cart-complete-icon-active"></use>
											</svg>
											<svg
												v-if="checkLunchesFull.indexOf(lunchIndex) === -1"
												width="25"
												height="25"
											>
												<use xlink:href="#lunch-cart-complete-icon"></use>
											</svg>
										</div>
										<label class="lunch-cart__person">
											<div class="lunch-cart-lunch__name">
												<span>{{ setlunchNames[lunchIndex].name }}</span>
												<span
													class="lunch-cart-lunch__name lunch-cart-lunch__name--green"
												>{{
														lunchesCount[lunchIndex]
													}} из {{ lunchesOuterCount[lunchIndex].length - 1 }} блюд</span>
												<span class="lunch-cart__name-item">
													<svg
														width="20"
														height="20"
													>
														<use xlink:href="#pencil"></use>
													</svg>
												</span>
											</div>
											<span
												class="lunch-cart__person-for"
												v-if="focus[lunchIndex]"
											>для </span>
											<input
												name="person-name"
												ref="nameInput"
												type="text"
												class="lunch-cart__name"
												:class="{'lunch-cart__name--filled': focus[lunchIndex]}"
												v-model="names[lunchIndex]"
												placeholder="подпишите для кого"
												@focus="focusInput($event, lunchIndex)"
												@blur="blurInput($event, lunchIndex)"
											>
										</label>
									</div>
									<div class="lunch-cart__icons">
										<img
											class="lunch-cart__icon"
											v-for="(lunchIcon, i) in lunchIconsComputed[lunchIndex]"
											:key="`lunch-cart-icon-${i}-${lunchIcon}`"
											:src="require(`@/assets/img/Personal/Menu/FoodTypes/${lunchIcon}.svg`)"
										/>
									</div>
								</div>
							</div>
							<div
								class="lunch-cart-item lunch-list-item lunch-cart-lunch-sev-item"
								v-show="lunchesOpenned.indexOf(lunchIndex) !== -1"
							>
								<LunchCartItem
									:isgift="false"
									:lunches="lunch"
									:lunchIndex="lunchIndex"
									@removeitem="removeItem"
								/>
							</div>
							<button
								v-show="showReplayLanchButton(lunchIndex)"
								class="button lunch-cart-item__replay"
								@click="replayBeforeLanch(lunchIndex)"
							>Повторить предыдущий бизнес-заказ
							</button>
						</div>
					</div>
				</div>
				<div
					v-if="currentGifts.length"
					class="lunch-cart__middle-gifts"
				>
					<LunchCartItem
						:lunches="currentGifts"
						:isgift="true"
						@removegift="removeGift"
					/>
				</div>
			</div>
			<div class="lunch-cart__bottom">
				<div class="lunch-cart__total">
					<!-- <button class="button button_red lunch-cart__btn" @click="buyCheck()">В корзину</button> -->
					<div class="lunch-cart-score">
						<div
							class="lunch-cart-score__row lunch-cart-score__mark"
							v-if="$store.state.businessMeal.balls"
						>
							<div class="lunch-cart-score__label">
								<p>Баллов начислится:</p>
							</div>
							<div class="lunch-cart-score__label-item">
								<div class="lunch-cart-score__value">+{{ $store.state.businessMeal.balls }}</div>
								<img :src="require('@/assets/img/icons/bonus.svg')"/>
							</div>
						</div>
						<div
							class="lunch-cart-score__row lunch-cart-score__sum"
							v-if="lunches.length > 1"
						>
							<div
								class="lunch-cart-score__status"
								:class="{'lunch-cart-score__status--mobile': isMobileApp}"
							>
								<div
									class="lunch-cart-score__status-progress"
									:style="progressStyle"
								></div>
								<div class="lunch-cart-score__status-content">
									<div class="lunch-cart-score__label">Собрано
										{{ lunches.length - checkLunchesFull.length }} из {{ lunches.length }}
									</div>
									<div class="lunch-cart-score__value"><span>{{ getLunchPrice }}</span></div>
								</div>
							</div>
						</div>
						<div
							class="lunch-cart-score__row lunch-cart-score__sum"
							v-else
						>
							<div
								class="lunch-cart-score__status"
								:class="{'lunch-cart-score__status--mobile': isMobileApp}"
							>
								<div
									class="lunch-cart-score__status-progress"
									:style="progressStyleOneLunch"
								></div>
								<div class="lunch-cart-score__status-content">
									<div class="lunch-cart-score__label">Собрано {{ amountLunches }} из
										{{ amountLunchesTotal }}
									</div>
									<div class="lunch-cart-score__value"><span>{{ getLunchPrice }}</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="lunch-cart__gift"
					@click="addGift"
					v-if="$store.state.businessMeal.podarki && $store.state.businessMeal.podarki.length > currentGifts.length"
				>
					<div class="lunch-cart__gift-label">Добавить подарок +</div>
					<div class="lunch-cart__gift-icon">
						<svg
							fill="transparent"
							width="40"
							height="40"
						>
							<use xlink:href="#cart-row__gift"></use>
						</svg>
					</div>
				</div>
			</div>
			<transition name="lunch-error">
				<div
					class="lunch-error-item lunch-cart__error"
					v-if="error"
				>
					<div class="lunch-cart__error-text">{{ getErrorText }}</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import LunchCartItem from '@/components/block/LunchCartItem.vue';
import {addThousandSeparator} from '@/helpers';

export default {
	props: ['lunchesOuter', 'currentLunchIndex', 'currentlunchname', 'show'],
	components: {LunchCartItem},

	/**
	 ** дата
	 **/
	data()
	{
		return {
			isMobileApp: null,
			error: false,
			active: -1,
			lunches: [],
			lunchesOpenned: [],
			names: {},
			lunchCount: 0,
			totalWeight: 0,
			currentGifts: [],
			lunchIcons: ['sup', 'salat', 'hot', 'garnir', 'vipechka', 'desert', 'juse', 'second', 'sauce', 'breakfast', 'roll', 'sushi', 'poke'],
			focus: [false, false, false],
		}
	},
	watch:
		{
			lunchesOuter: {
				/**
				 * следит за изменениями ланчей снаружи
				 */
				handler: function (newValue)
				{
					this.lunches = newValue;
				},
				deep: true
			},
			names: {
				/**
				 * следит за изменениями имен и посылает их наверх
				 */
				handler: function ()
				{
					this.$emit('setname', this.names);
					this.$emit('setcurrentlunchname', this.names[this.lunchesOpenned[0]]);
				},
				deep: true
			},
			currentLunchIndex: {
				/**
				 * следит за изменениями активного ланча
				 */
				handler: function (newValue)
				{
					this.lunchesOpenned = [newValue];
					this.$emit('setcurrentlunchname', this.names[this.lunchesOpenned[0]]);
				},
				deep: true
			},
			currentlunchname: {
				handler(newValue)
				{
					this.names[this.currentLunchIndex] = newValue;
				},
				deep: true
			}
		},

	computed:
		{
			// Возвращает текст ошибки
			getErrorText()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

				return `Вы не собрали свой ${mealText}`;
			},
			amountLunchesTotal()
			{
				if (this.lunches[0] && Object.keys(this.lunches[0]).length > 1)
				{
					return Object.keys(this.lunches[0]).length;
				}
			},
			amountLunches()
			{
				return this.lunches[0] ? Object.values(this.lunches[0]).filter(item => item !== false).length : 0;
			},
			progressStyle()
			{
				const amount = this.lunches.length;
				const current = this.lunches.length - this.checkLunchesFull.length;
				return {
					'--width': (current * 100) / amount + '%'
				}
			},
			progressStyleOneLunch()
			{
				let amount;
				if (this.lunches[0] && Object.keys(this.lunches[0]).length > 1)
				{
					amount = Object.keys(this.lunches[0]).length;
				}
				const amountValue = this.lunches[0] ? Object.values(this.lunches[0]).filter(item => item !== false).length : 0;
				return {
					'--width': (amountValue * 100) / amount + '%'
				}
			},
			/**
			 * возвращать lunchIcons столько сколько приходит в lunches
			 */
			lunchIconsComputed()
			{
				const icons = [];
				for (const i in this.lunches)
				{
					if (!icons[i]) icons[i] = [];

					for (let key in this.lunches[i])
					{
						if (this.lunches[i][key] === false)
						{
							icons[i].push(key);
						}
					}
				}

				return icons;
			},
			getLunchPrice()
			{
				let price = this.$store.state.businessMeal.price;

				for (let lunch of this.lunchesOuter)
				{
					if (!lunch.box) continue;
					price += lunch.box.price;
				}

				return addThousandSeparator(price);
			},

			/**
			 * проверяет ланчи на заполненость
			 */
			checkLunchesFull()
			{
				const arrNotFullLunches = [];

				for (let index in this.lunches)
				{
					let lunch = this.lunches[index];
					for (let mealIndex in lunch)
					{
						if (!lunch[mealIndex])
						{
							arrNotFullLunches.push(Number(index))
							break
						}
					}
				}

				return arrNotFullLunches
			},
			// получать количество блюд из обьектов внутри массива lunches если они есть
			lunchesCount()
			{
				let count = 0;
				let lucnhCount = [];
				for (let item of this.lunches)
				{
					for (let key in item)
					{
						if (item[key])
						{
							count++;
						}
					}
					lucnhCount.push(count - 1);
					count = 0;
				}
				return lucnhCount;
			},
			// проитись по массиву lunches и каждый обьект преоброзовать в массив
			lunchesOuterCount()
			{
				let lunches = [];
				for (let item of this.lunches)
				{
					let lunch = [];
					for (let key in item)
					{
						lunch.push(item[key]);
					}
					lunches.push(lunch);
				}
				return lunches;
			},
			// добавить статичные имена в ланчи
			setlunchNames()
			{
				const {businessMealType} = this.$store.state;
				const mealText = businessMealType === "lunch" ? "ланч" : "ужин";

				let names = [`Первый ${mealText}`, `Второй ${mealText}`, `Третий ${mealText}`, `Четвертый ${mealText}`, `Пятый ${mealText}`];
				// добавить эти имена в массив lunches к каждому обьекту
				for (let i = 0; i < this.lunches.length; i++)
				{
					this.lunches[i].name = names[i];
				}
				return this.lunches;
			},
		},

	/**
	 * записывает ланчи в дату из пропса
	 */
	mounted()
	{
		this.lunches = this.lunchesOuter;
		this.lunchesOpenned.push(this.currentLunchIndex);

		setTimeout(() =>
		{
			for (let index in this.lunches) this.$set(this.names, index, '');
		}, 10);

		this.addGift();

		const userAgent = window.navigator.userAgent;
		this.isMobileApp = userAgent.match(/mobileApp/i);
	},

	methods:
		{
			close()
			{
				this.$emit("close")
			},
			toggleShowLunchItems(index)
			{
				if (this.lunchesOpenned.includes(index))
				{
					this.lunchesOpenned.splice(this.lunchesOpenned.indexOf(index), 1);
				} else
				{
					this.lunchesOpenned.push(index);
				}
			},
			// менять плэйсхолдер при фокусе и при блюре
			focusInput(event, index)
			{
				//при фокусе добовлть в инпут валуе слово "для"
				let input = event.target;
				input.placeholder = '';
				if (input.value === '')
				{
					this.focus[index] = true;
				}
			},
			blurInput(event, index)
			{
				//при блюре убрать в инпут валуе слово "для"
				let input = event.target;
				input.placeholder = 'подпишите для кого';
				this.focus[index] = input.value !== '';
			},
			/**
			 * добавить подарок в ланч
			 */
			addGift()
			{
				for (let gift of this.$store.state.businessMeal.podarki)
				{
					let giftHere = true;
					for (let giftCheck of this.currentGifts) if (giftCheck.id === gift.id) giftHere = false;

					if (giftHere)
					{
						this.currentGifts.push(gift);
						return
					}
				}
			},
			/**
			 * удалить подарок из ланча
			 */
			removeGift(gift)
			{
				for (let index in this.currentGifts) if (this.currentGifts[index].id === gift.id) this.currentGifts.splice(index, 1);
			},

			/**
			 * удаления блюда из ланча
			 */
			removeItem(lunchIndex, lunchItem)
			{
				this.$emit('removemeal', lunchIndex, lunchItem.type.code)
			},

			/**
			 * проверка на завершенность ланчей
			 */
			buyCheck()
			{
				for (let index in this.lunches)
				{
					let lunch = this.lunches[index];

					for (let mealIndex in lunch)
					{
						if (!lunch[mealIndex])
						{
							this.error = true;
							return false;
						}
					}
				}
				this.error = false;
			},
			replayBeforeLanch(lunchIndex)
			{
				this.$emit('replay-lanch', lunchIndex);
			},

			// если до текущего ланча есть заполненные ланчи
			// и текущий ланч не заполнен
			// - показываем кнопку повторить предыдущий ланч
			showReplayLanchButton(lanchIndex)
			{
				if (lanchIndex === 0) return false;

				let currentLanch = this.lunchesOuter[this.currentLunchIndex];
				for (let lanchMealCode in currentLanch) if (currentLanch[lanchMealCode] !== false) return false;

				return this.currentLunchIndex === lanchIndex;
			},
		},
}
</script>
<style lang="scss">
.lunch-cart__top-text
{
	display: block;
	width: 100%;
	padding-bottom: 12px;
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	color: #3D4248;
	border-bottom: 1px solid #E5E5E7;
}

.lunch-cart-lunch__sev-headline__flex
{
	display: flex;
	flex: 1 1 100%;
	margin-bottom: 12px;
}

.lunch-cart-lunch__name
{
	display: flex;
	justify-content: space-between;
	width: 100%;
	font-family: $mainFontNew;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	color: #3D4248;
	white-space: nowrap;
	order: 1;

	span:first-child
	{margin-right: 6px;}

	&--green
	{color: #40AE49;}
}

.lunch-cart-item__replay
{margin-bottom: 22px;}

.lunch-cart__person-for
{
	font-family: $mainFontNew;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	color: #979797;
}

.lunch-error-item
{
	margin-right: 10px;
	width: 100%;
}

.lunch-error-enter, .lunch-error-leave-to
{opacity: 0; }

.lunch-error-leave-active, .lunch-error-enter-active
{@include ease(.3s); }

.lunch-list-item
{
	transition: all .5s;
	margin-right: 10px;
	width: 100%;
}

.lunch-list-enter, .lunch-list-leave-to
{opacity: 0; }

.lunch-list-leave-active, .lunch-list-enter-active
{
	transition-duration: 0.2s;
	transition-property: height, opacity, transform;
	overflow: hidden;
}

.lunch-cart
{
	background-color: #fff;
	padding: 28px 32px;
	width: 100%;
	min-width: 354px;
	border-radius: 10px;
	position: relative;
	box-shadow: 0 13px 20px rgba(0, 0, 0, 0.05);
	border: 1px solid #F0F0F0;
}

.lunch-cart__icons
{
	display: flex;
	flex-wrap: wrap;
	gap: 10px 10px;
	margin-bottom: 12px;

	img
	{
		width: 48px;
		height: 50px;
		border: 1px dashed #747678;
		border-radius: 6px;
		padding: 11px;
	}
}

.lunch-cart__top
{
	display: flex;
	display: -webkit-box;
	flex-direction: column;
	align-items: center;
	padding-bottom: 12px;
}

.lunch-cart__person
{white-space: nowrap; }

.lunch-cart__name-item
{
	order: 3;
	line-height: 0;
	display: inline-block;
	cursor: pointer;
}

.lunch-cart__name
{
	border: none;
	text-align: left;
	max-width: 300px;
	width: 100%;
	background-color: transparent;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	font-family: $mainFontNew;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	color: #979797;
	padding-right: 17px;
	letter-spacing: .1px;

	&--filled
	{
		color: green;
	}

	&::placeholder
	{
		opacity: 0.3;
	}

	&:focus
	{
		border: none;
		outline: none;
	}
}

.lunch-cart__middle
{
	padding-bottom: 20px;
	overflow: hidden;
}

.lunch-cart__middle-gifts
{margin-top: 20px; }

.lunch-cart__nutrition
{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.lunch-cart__row
{
	display: flex;
	font-family: $mainFont;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 100%;
	text-align: right;
	letter-spacing: .01px;

	&:not(:last-child)
	{padding-bottom: 12px; }
}

.lunch-cart__label
{color: $greyText; }

.lunch-cart__total
{
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	align-items: flex-end;
}

.lunch-cart__btn
{
	font-family: $mainFontBold;
	color: #fff;
	width: 170px;
	height: 50px;
	letter-spacing: .1px;
	font-size: 16px;
	user-select: none;
}

.lunch-cart-score
{
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 4px;
}

.lunch-cart-score__row
{
	display: flex;
	letter-spacing: .1px;
}

.lunch-cart-score__sum
{
	font-family: $mainFontNew;
	color: $textColor;
	font-style: normal;
	font-weight: 700;
	display: flex;
	justify-content: center;
	padding-bottom: 2px;

	.lunch-cart-score__label
	{
		font-size: 14px;
		line-height: 24px;
		color: #FFFFFF;

		&::after
		{
			content: '';
			display: inline-block;
			width: 2px;
			height: 18px;
			background: #F0F2F6;
			margin-left: 12px;
			vertical-align: middle;
		}
	}

	.lunch-cart-score__value
	{
		display: flex;
		padding: 4px 0 4px 9px;
		font-size: 20px;
		line-height: 40px;
		letter-spacing: -1px;
		color: #FFFFFF;

		&:after
		{
			content: '₽';
			font-size: 20px;
			padding-left: 6px;
		}

		span
		{height: 40px;}
	}
}

.lunch-cart-score__status
{
	position: relative;
	background-color: #747678;
	width: 100%;
	border-radius: 8px;
}

.lunch-cart-score__status-content
{
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	background-image: url('~@/assets/img/cart-total-bg.webp');
	background-repeat: no-repeat;
	background-size: 100%;
}

.lunch-cart-score__status-progress
{
	width: var(--width);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	background: #FD470E;
	border-radius: 8px;
	Z-index: 0;
}

.lunch-cart-score__mark
{
	font-family: $mainFontNew;
	font-style: normal;
	display: flex;
	margin-bottom: 16px;
	flex: 1;
	justify-content: space-between;

	.lunch-cart-score__label p
	{
		position: relative;
		display: flex;
		align-items: center;
		color: $green;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		white-space: nowrap;
	}

	.lunch-cart-score__label p::after
	{
		content: '';
		height: 10px;
		width: 100%;
		background-image: radial-gradient(#ebebeb 40%, transparent 50%);
		background-size: 10px 10px;
		background-repeat: repeat-x;
		background-position: 0 bottom;
		display: block;
		margin-left: 10px;
	}

	.lunch-cart-score__value
	{
		text-align: right;
		padding: 0 3px 0 4px;
		font-weight: 700;
	}

	.lunch-cart-score__label-item
	{
		display: flex;
		align-items: center;
		padding: 0 7px 0 4px;
		border-radius: 4px;
		background: #40AE49;
		font-family: $mainFontNew;
		font-size: 14px;
		color: #fff;
	}
}

.lunch-cart-lunch__sev-head
{
	display: flex;
	justify-content: space-between;
	// padding-bottom: 12px;
	background-position: bottom;
	background-size: 5px 2px;
	background-repeat: repeat-x;
	align-items: baseline;
	letter-spacing: .1px;
	position: relative;
	user-select: none;

	svg
	{fill: transparent;}
}

.lunch-cart-lunch__sev-headline
{
	flex-basis: 100%;
	flex-wrap: wrap;
	font-family: $mainFontBold;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 100%;
	color: $textColor;
	display: flex;
	align-items: flex-start;
	cursor: pointer;

	label
	{width: 100%;}
}

.lunch-cart-lunch__complete-icon
{
	line-height: 0;
	padding-right: 10px;
}

.lunch-cart-several:not(:first-child)
{padding-top: 12px; }

.lunch-cart-several
{
	border-bottom: 1px solid #E5E5E7;
}

.lunch-cart-several .active-lunch
{

	.lunch-cart-lunch__sev-head
	{background-image: none;}

	.lunch-cart-lunch__sev-headline
	{color: $red;}
}

.lunch-cart__error
{
	background-color: #fff;
	height: 15px;
}

.lunch-cart__error-text
{
	background-color: $red;
	font-family: $mainFontBold;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 100%;
	text-align: center;
	color: #FFFFFF;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	height: 22px;
	letter-spacing: .1px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0 0 10px 10px;
}

.lunch-cart__adaptive
{
	display: none;
}

.lunch-cart__adaptive-close {
	position: absolute;
	right: 10px;
	top: -20px;
	font-size: 0;
	line-height: 0;
	padding: 4px;
	z-index: 100;

	img {
		width: 26px;
		height: 26px;
		z-index: 1000;
	}
}

.lunch-cart__adaptive_rectangle_thrid
{
	position: absolute;
	width: 44px;
	height: 5px;
	border-radius: 10px;
	background: #C4C4C4;
	right: 48%;
	top: -158px;
	z-index: 1;

	&--active
	{top: -15px}

	&--mobile
	{top: -94px}
}

.lunch-cart__gift
{
	background-color: rgba($red, 0.1);
	border-radius: 10px;
	position: relative;
	text-align: center;
	height: 48px;
	margin-top: 30px;
	cursor: pointer;
	user-select: none;
	border: 1px $red dashed;
}

.lunch-cart__gift-label
{
	font-family: $mainFontBold;
	font-size: 12px;
	line-height: 100%;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: $red;
	padding-top: 18px;
	margin-left: -6px;
}

.lunch-cart__gift-icon
{
	line-height: 0;
	position: absolute;
	right: 25px;
	top: 8px;

	svg
	{
		width: 30px;
		height: 30px;
	}
}

@media (max-width: 1200px)
{
	.lunch-cart-score__status
	{
		position: fixed;
		bottom: 82px;
		right: 50%;
		left: 50%;
		transform: translateX(-50%);
		width: 80%;

		&--mobile
		{bottom: 18px}
	}
	.lunch-cart
	{
		padding: 0 42px 60px 42px;
		border-radius: 0;
		height: 63vh;
		display: flex;
		flex-direction: column;
		border: none;
	}
	.lunch-cart__top
	{padding-bottom: 10px; }
	.lunch-cart__name
	{font-size: 18px; }
	.lunch-cart__name-item
	{
		svg
		{width: 15px; height: 15px; }
	}
	.lunch-list-item
	{margin-top: 6px; }
	.lunch-cart-several:not(:first-child)
	{padding-top: 12px; }
	.lunch-cart__row:not(:last-child)
	{padding-bottom: 9px; }
	.lunch-cart__nutrition
	{margin-bottom: 10px; }
	.lunch-cart__total
	{
		margin-top: 0;
		flex-direction: column-reverse;
		align-items: unset;
	}
	.lunch-cart-score__sum
	{
		justify-content: space-between;
	}
	.lunch-cart-score__mark
	{display: flex;}
	.lunch-cart__btn
	{
		width: 290px;
		margin: 0 auto;
	}
	.lunch-cart__adaptive
	{
		display: block;
	}
	.lunch-cart__adaptive_icon
	{
		position: absolute;
		z-index: 1;
		top: -69px;
		left: 36px;
	}
	.lunch-cart__adaptive_circle,
	.lunch-cart__adaptive_rectangle_second
	{
		position: absolute;
		background-color: #fff;
	}
	.lunch-cart__adaptive_circle
	{
		width: 20px;
		height: 20px;
		top: -38px;
		border-radius: 50%;
		left: 14px;

		&:last-child
		{left: 93px; }
	}
	.lunch-cart__adaptive_rectangle_second
	{
		padding-top: 32px;
		top: -173px;
		height: 110px;
		// border-radius: 14px 13px 0 0;
		border-radius: 24px 24px 0 0;
		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);

		&:before
		{
			content: '';
			position: absolute;
			width: 100%;
			height: 70px;
			z-index: -1;
			top: 100%;
			box-shadow: 0px -50px 10px rgba(0, 0, 0, 0.15);
		}

		&--active
		{
			height: 32px;
			top: -32px;
		}

		&--mobile
		{top: -109px}
	}
	.lunch-cart__adaptive_rectangle_second
	{width: 100vw;}
	.lunch-cart__adaptive_rectangle-for-drop
	{
		position: absolute;
		background-color: #c4c4c4;
		border-radius: 10px;
		width: 35px;
		height: 5px;
		left: calc(50% - 19px);
		top: -33px;
	}
	.lunch-cart__adaptive-label
	{
		position: absolute;
		display: flex;
		align-items: center;
		top: -24px;
		right: 13px;
	}
	.lunch-cart__adaptive-label__icon
	{line-height: 0; cursor: pointer; }
	.lunch-cart__adaptive-label__text,
	.lunch-cart__adaptive-label__text-sev
	{
		font-family: $mainFontBold;
		font-size: 13px;
		line-height: 16px;
		text-align: right;
		color: $textColor;
		padding-right: 9px;
	}
	.lunch-cart__adaptive-label__text-sev
	{color: $red; }
	.lunch-cart__error-text
	{border-radius: 0; }

	.lunch-cart__adaptive_first-helpful-block-wr
	{
		height: 47px;
		width: 68px;
		position: absolute;
		top: -34px;
		left: 31px;
		overflow: hidden;
	}
	.lunch-cart__adaptive_first-helpful-block
	{
		margin-top: -38px;
		border-radius: 50%;
		width: 69px;
		height: 69px;
		box-shadow: 0 0 0 16px #fff;
	}
	.lunch-cart__adaptive_icon
	{
		&:before
		{
			content: '';
			border-radius: 50%;
			position: absolute;
			width: 100%;
			height: 60px;
			z-index: -1;
			top: 0;
			box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.15);
		}

		svg
		{width: 60px; height: 60px; }
	}
	.lunch-cart__middle
	{
		height: 100%;
		max-height: 66vh;
		min-height: 15vh;
		padding-right: 5px;
		overflow-y: scroll;
	}
	.lunch-cart-several-wr
	{height: fit-content; }
	.lunch-cart__bottom
	{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 10px 0;
		min-height: fit-content;
	}
	.lunch-cart__gift-icon
	{right: 15px; }
	@media (max-width: 520px)
	{
		.lunch-cart__adaptive_rectangle_thrid
		{right: 44%;}
	}
}
</style>
